<template>
  <v-card id="edit-delete">
    <v-card-text>
      <div class="center-align pt-6">
        変更内容を破棄してもよろしいですか？
      </div>
    </v-card-text>
    <v-card-actions class="modal-footer pa-0">
      <div class="width-100 center-align pb-5 pt-3">
        <v-btn id="edit-delete-cancel-btn" @click="$store.commit('seteditDeleteDialog', null)" width="128px" variant="outlined" color="primary" class="font-bold mx-2 font-color-top-primary-outline-btn-text">キャンセル</v-btn>
        <v-btn id="edit-delete-ok-btn" @click="reset()" width="112px" variant="flat" color="primary" class="font-bold mx-2 font-color-primary-btn-text">OK</v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'edit-delete',
  methods: {
    reset: function () {
      this.$store.state.editDeleteDialog.afterFunction()
      this.$store.state.editDeleteDialog.resetForm.resetValidation()
      this.$store.commit('seteditDeleteDialog', null)
    }
  }
}
</script>
