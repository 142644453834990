import { API } from 'aws-amplify'
export default {
  mounted () {
    window.gmoGetTokenCallBack = this.gmoGetTokenCallBack
  },
  methods: {
    // gmoのtoken取得コールバック関数
    // request: {
    //   MemberId: string;
    // }
    gmoGetTokenCallBack: function (value) {
      this.$store.commit('setGmoCardInfo', value)
    },
    // イベント一覧取得
    // request: {
    //   regist_month: "2021-09-01",
    //   event_month: "2021-09-01",
    //   status:[1,3] 配列※
    // }
    getEventList: async function (param) {
      const self = this
      let returnResponse = null
      const apiName = 'integrationEvent'
      const path = '/events'
      const myInit = {
        queryStringParameters: param,
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        console.log(response)
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // イベント一覧取得(一般用)
    // request: {
    //   regist_month: "2021-09-01",
    //   event_month: "2021-09-01",
    //   status:[1,3] 配列※
    // }
    getEventListCommon: async function (param) {
      const self = this
      let returnResponse = null
      const apiName = 'integrationNotAuthEvent'
      const path = '/events/participants'
      const myInit = {
        queryStringParameters: param,
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        console.log(response)
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // イベント登録
    // request: {
    //   event_name: string | null; // イベント名
    //   event_target: string | null; // イベント対象
    //   students_number: number; // 募集人数(上限無しの場合、0を設定)
    //   regist_start_date: string | null; // 応募期間from
    //   regist_end_date: string | null; // 応募期間to
    //   event_start_date: string | null; // 開催日from
    //   event_end_date: string | null; // 開催日to
    //   event_fee: number; // 料金(参加費無しの場合、0を設定)
    //   member_fee: number; // 会員料金（参加費無しの場合、0を設定）
    //   overview: string | null; // イベント概要(DBはvarchar(10000)で定義)
    //   is_member: number //1:会員 2:全員,
    //   over_app: number //募集人数超過許容 1:OK 2:NG,
    //   payment_type:必須 Array
    // },
    postRegisterEvent: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integrationEvent'
      const path = '/events'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // イベント編集
    // request: {
    //   event_name: string | null; // イベント名
    //   event_target: string | null; // イベント対象
    //   students_number: number; // 募集人数(上限無しの場合、0を設定)
    //   regist_start_date: string | null; // 応募期間from
    //   regist_end_date: string | null; // 応募期間to
    //   event_start_date: string | null; // 開催日from
    //   event_end_date: string | null; // 開催日to
    //   event_fee: number; // 料金(参加費無しの場合、0を設定)
    //   member_fee: number; // 会員料金（参加費無しの場合、0を設定）
    //   overview: string | null; // イベント概要(DBはvarchar(10000)で定義)
    //   is_member: number //1:会員 2:全員,
    //   over_app: number //募集人数超過許容 1:OK 2:NG,
    //   payment_type:必須 Array
    // },
    putRegisterEvent: async function (id, body) {
      const self = this
      let returnResponse = null
      const apiName = 'integrationEvent'
      const path = '/events/' + id
      const myInit = {
        body: body
      }

      await API.put(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // request: {
    //   id: number; // post /eventsのresponseのIDを設定
    //   document_url1: String,
    //   document_url2: String,
    //   document_url3: String,
    //   document_url4: String,
    //   document_url5: String
    // }
    postRegisterEventFile: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integrationEvent'
      const path = '/events_document_url'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // イベント詳細取得（管理者）
    getEventDetailAdmin: async function (id) {
      const self = this
      let returnResponse = null
      const apiName = 'integrationEvent'
      const path = '/events/details/' + id
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        console.log(response)
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // イベント詳細取得（参加者）
    getEventDetail: async function (id) {
      const self = this
      let returnResponse = null
      const apiName = 'integrationNotAuthEvent'
      const path = '/events/participants/' + id
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        console.log(response)
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // event出欠登録
    // request: {
    //   participants_id: number;
    //   is_attend: boolean | null;  // 0:未参加 1:参加
    // }
    putRegisterEventAttendance: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integrationEvent'
      const path = '/events/attendance'
      const myInit = {
        body: body
      }

      await API.put(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // イベント参加申込
    // request: {
    //   participants: {
    //     events_id: number,
    //     name: string,
    //     furigana: string,
    //     email: string,
    //     phone_number: string,
    //     birthday: string,
    //     gender: number, // 0:初期値 1:男性 2:女性 3:その他
    //     attention: string,
    //     parent_name: string,
    //     parent_furigana: string,
    //     parent_birthday: string,
    //     payment_status: number 1:支払いなし（無料イベント  2:当日支払  3:クレジット  payment_statusが1、2の時は、paymentの設定は不要
    //     is_member: number; 1:会員ではない 2:会員
    //   },
    //   payment :{
    //     amount: numbet,
    //     token: string  // クレジットカード登録と同様にGMOのjavascriptでトークンを発行した値を設定
    //   }
    // }
    postEventRequest: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = !this.$store.state.loginId ? 'integrationEventPayment' : 'integrationEventPrivatePayment'
      const path = !this.$store.state.loginId ? '/participants/general' : '/participants'
      const myInit = {
        body: body
      }
      console.log(apiName)

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 支払いcancel
    // request: {
    //   participants_id: number
    // }
    postPaymentCancel: async function (body) {
      const self = this
      let returnResponse = null
      const apiName = 'integrationEventPrivatePayment'
      const path = '/payments/cancel'
      const myInit = {
        body: body
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // イベント削除API
    // request:
    // {
    // }
    deleteEvent: async function (id) {
      const self = this
      let returnResponse = null
      const apiName = 'integrationEvent'
      const path = '/events/' + id

      await API.del(apiName, path).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // イベント中止
    // request: {
    //   participants_id: number
    // }
    postEventCancel: async function (id) {
      const self = this
      let returnResponse = null
      const apiName = 'integrationEvent'
      const path = '/events/edit/' + id
      const myInit = {
      }

      await API.post(apiName, path, myInit).then(response => {
        returnResponse = response
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 支払い情報マスタ取得
    getPaymentTypeList: async function () {
      const self = this
      let returnResponse = null
      const apiName = 'integrationNotAuthEvent'
      const path = '/masters/payment_type'
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        console.log(response)
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // 入会者チェック
    getIsMember: async function () {
      const self = this
      let returnResponse = null
      const apiName = 'integrationNotAuthEvent'
      const path = '/user/is_member'
      const myInit = {
        response: true
      }

      await API.get(apiName, path, myInit).then(response => {
        console.log(response)
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e.response)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    },
    // お知らせ既読者一括取得API
    // param {
    // }
    getEventsList: async function (param) {
      const self = this
      let returnResponse = null
      const apiName = 'integrationEvent'
      const path = '/events/details'
      const myInit = {
        response: true,
        queryStringParameters: param
      }

      await API.get(apiName, path, myInit).then(response => {
        returnResponse = response.data
        if (returnResponse.result_information && returnResponse.result_information.result === 'ERROR') {
          self.globalApiError(returnResponse.result_information.error_message)
          returnResponse = null
        }
      }).catch(e => {
        // エラー処理
        self.globalHttpError(e)
        console.log(e)
        console.log(e.response.data)
      })
      if (returnResponse !== null) {
        return returnResponse
      } else {
        throw new Error('error')
      }
    }
  }
}
