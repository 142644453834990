import { createApp } from 'vue'
const app = createApp({})

const infiScroll = {
  mounted: function (el, binding) {
    const f = function (evt) {
      const pageLoad = binding.value.pageLoad
      if (binding.value.function(el, pageLoad, evt)) {
        el.removeEventListener('scroll', f)
      }
    }
    el.addEventListener('scroll', f)
  }
}

const infiScrollSp = {
  mounted: function (el, binding) {
    const f = function (evt) {
      const pageLoad = binding.value.pageLoad
      if (binding.value.function(pageLoad, evt)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
}

const scroll = {
  const: function (el, binding) {
    const f = function (evt) {
      if (binding.value(evt)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
}

app.directive('infi-scroll', infiScroll)
app.directive('infi-scroll-sp', infiScrollSp)
app.directive('scroll', scroll)
